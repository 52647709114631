import React, { useRef, useState } from 'react'
import SecondaryLayout from '../layouts/SecondaryLayout'
import Column from '../layouts/Column'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Title from '../components/Title/Title'
import PagePadding from '../components/PagePadding/PagePadding'
import ReactHtmlParser from 'react-html-parser'
import * as Fuse from 'fuse.js'

export default function Search(props) {
	const { allWordpressWpSearch, allSitePage } = useStaticQuery(graphql`
		query {
			allWordpressWpSearch(
				filter: {
					subtype: {
						nin: [
							"action_tryout"
							"kalender"
							"post"
							"nieuws"
							"faq"
							"kalender"
							"page"
							"review"
							"materiaal_onderhoud"
							"covid_popup"
						]
					}
				}
			) {
				edges {
					node {
						title
						url
						type
						subtype
						wordpress_id
					}
				}
			}
			allSitePage {
				edges {
					node {
						path
					}
				}
			}
		}
	`)

	const wordpressWpSearch = allWordpressWpSearch.edges
	const sitePages = allSitePage.edges

	const searchResults = wordpressWpSearch
		.map((item) => {
			const _item = item.node
			const url = sitePages
				.filter((page) => {
					const pageUrl = page.node.path
					return pageUrl.includes(_item.wordpress_id)
				})
				.map((page) => page.node.path)
			return {
				title: _item.title,
				url: `${url[0]}/`,
			}
		})
		.filter((item) => item.title && item.url)

	searchResults.push(
		{
			title: 'Kalender',
			url: '/kalender',
		},
		{
			title: 'Veelgestelde vragen',
			url: '/faq',
		},
		{
			title: 'Nieuws',
			url: '/nieuws',
		},
		{
			title: 'Materiaal en onderhoud',
			url: '/materiaal-en-onderhoud/',
		},
	)

	const url = typeof window !== 'undefined' ? window.location.href : undefined
	const query = url ? new URL(url).searchParams.get('q') : ''
	const [searchValue, setSearchValue] = useState(query)

	const options = {
		shouldSort: true,
		threshold: 0.6,
		location: 0,
		distance: 100,
		maxPatternLength: 32,
		minMatchCharLength: 1,
		keys: ['title', 'url'],
	}

	const fuse = new Fuse(searchResults, options)
	const inputRef = useRef()

	function getSearchResults(searchQuery) {
		const result =
			searchValue || query
				? fuse.search(searchValue || query)
				: searchResults
		return (
			<ul>
				{result.length ? (
					result.map(
						(result) =>
							result.url && (
								<li className={'searchresult'} key={result.url}>
									<Title
										className={'no-margin'}
										heading={4}
										text={ReactHtmlParser(result.title)}
										wave={false}
									/>
									<Link to={result.url}>Naar de pagina</Link>
								</li>
							),
					)
				) : (
					<p>Geen resultaten...</p>
				)}
			</ul>
		)
	}
	return (
		<SecondaryLayout>
			<PagePadding>
				<Column>
					<Title heading={3} text={'Zoeken'} wave={true} />
					<div className={'searchholder'}>
						<input
							type="search"
							ref={inputRef}
							placeholder={'Zoeken'}
							onKeyUp={(e) => setSearchValue(e.target.value)}
							defaultValue={searchValue || query}
						/>
						<button
							onClick={() => {
								inputRef.current.value = ''
								setSearchValue('')
							}}
						>
							Alles tonen
						</button>
					</div>
					<Column>
						{typeof window !== 'undefined' ? (
							getSearchResults(searchValue || query)
						) : (
							<p>Geen resultaten...</p>
						)}
					</Column>
				</Column>
			</PagePadding>
		</SecondaryLayout>
	)
}
